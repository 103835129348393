
import Vue from 'vue'
import { addRow, checkCondition, getOptions, isNotNested, parseData, parseForm, removeRow } from '../../mixins/formData'

export default Vue.extend({
  name: 'UserOfficeForm',

  data: () => {
    const formData: any = {}
    const subformData: any = {}
    return {
      isLoading: true,
      errors: null,
      errorMsg: '',
      formName: 'user_data',
      user: null,
      formObj: {},
      formData,
      subformData,
    }
  },

  computed: {
    authUser () {
      return this.$store.state.user
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    currentPart () {
      return this.$route.params.part ||this.$store.state.currentAwardConf.forms.user_project_save.parts[0].key
    },
    didSignup() {
      return this.$store.state.didSignup
    },
    formParts () {
      // award spec. user form
      // console.log(this.$store.state.userConfAward);
      if (this.$store.state.userConfAward && this.$store.state.userConfAward.forms && this.$store.state.userConfAward.forms[this.formName].parts) {
        return this.$store.state.userConfAward.forms[this.formName].parts
      }

      if (!this.$store.state.userConf) {
        return null
      }

      return this.$store.state.userConf.forms[this.formName].parts
    },
  },

  methods: {
    addRow (field: any, index: number) {
      const subformData = addRow(field, index, this.subformData)
      this.subformData = subformData
    },

    initOffice(office: any) {
      if (office === null) {
        office = {}
      }
      if (office) {
        const { formData, subformData } = this.parseData(this.formParts, office, this.formData)
        this.formObj = office
        this.formData = Object.assign({}, this.formData, formData)
        this.subformData = Object.assign({}, this.subformData, subformData)
        this.isLoading = false
      }
    },

    lastStep () {
      if (!this.formParts) return false
      return this.currentPart === this.formParts[this.formParts.length - 1].key
    },

    removeRow (field: any, index: number) {
      const { formData, subformData } = removeRow(field, index, this.formData, this.subformData)
      this.formData = formData
      this.subformData = subformData
    },

    async saveForm() {
      if (this.authUser !== null) {
        this.$store.commit('setProcessing', true)
        let formData = this.parseForm(this.formParts, this.formData)[this.currentPart]
        let { formName, currentPart } = this
        formData.email = this.authUser.email
        // console.log('saveForm -> formData', formData)
        let data: any = {
          formData,
          formName,
          currentPart,
          id: this.authUser.id,
          year: this.currentAward.year,
          awardType: this.currentAward.awardType
        }

        this.$store
          .dispatch('saveUserOffice', data)
          .then((office: any) => {
            console.log('saveUserOffice -> office: ', office);
            if (office) {
              this.$store.commit('setAlert', {
                type: 'info',
                message: 'Ihre Daten wurden erfolgreich gespeichert.'
              })
              this.initOffice(office)
            }
            if (this.didSignup) {
              this.$router.push('/anmelden/info')
            }
            this.$store.commit('setProcessing', false)
          }).catch((err: Error) => {
            if (err.message.indexOf('errorObject') >= 0) {
              const errors = JSON.parse(err.message)
              this.errors = errors.errorObject
              this.$store.commit('setAlert', {
                type: 'error',
                message: 'Fehler beim Speichern! Bitte prüfen Sie die Eingaben.',
              })
            }
            this.$store.commit('setProcessing', false)
          })
      }

      return false
    },

    checkCondition,
    getOptions,
    isNotNested,
    parseData,
    parseForm,
  },

  mounted () {
    this.$store
      .dispatch('getOffice')
      .then(this.initOffice)
  },
})
